import React from 'react'
import { PageProps } from 'gatsby'
import { Layout } from '../../components/layout'
import { SEO } from '../../components/seo'
import { Menu } from '../../components/menu'

import * as styles from '../../styles/pages/solutions.module.scss'
import mainHeader from '../../images/solutions/controladoriaMain.jpeg'

import { Contact } from '../../components/contact/contact'
import { Footer } from '../../components/footer'
import { FaqSection } from '../../components/faq'
import clsx from 'clsx'
import { MethodologySection } from '../../components/methodology'
import { SliderCustomers } from '../../components/sliderCustomers/sliderCustomers'
import { StatisticsSection } from '../../components/statistics'
import { controladoriaList } from '../../utils/customersList'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import BreadcrumbComponent from '../../components/breadcrumb'

const faqItems = [
  {
    question: 'Quais os benefícios da consultoria em gestão empresarial?',
    answer: `A implementação de boas práticas de planejamento e gestão empresarial possibilita alavancar os resultados de uma organização, reduzindo custos, aumentando a produtividade e descentralizando a tomada de decisões.
			a definição de metas ousadas e ao mesmo tempo alcançáveis, a tomada de decisões de forma assertiva, mensuração do desempenho da equipe e identificação de melhorias, visão ampla de processos e maior motivação e engajamento de colaboradores.
			`,
  },
  {
    question: 'Quanto tempo leva o projeto de consultoria?',
    answer:
      'Depende do nível de maturidade de gestão da empresa. Projetos como esse levam em torno de 6 a 12 meses, sendo possível realizar a renovação da contratação. Trabalhamos de forma ágil para que a cada reunião de status do projeto possamos evoluir para os resultados.',
  },
  {
    question: 'Quais os fatores de sucesso do projeto de consultoria?',
    answer:
      'Para colher os frutos do trabalho de consultoria é preciso que a alta liderança esteja engajada a implementar mudanças na organização, que podem implicar em adoção de novas tecnologias, realocação de pessoas, investimento em treinamentos e até mesmo questões de ordem comportamental e cultural.',
  },
  {
    question: 'Como entender se é o momento de contratar uma consultoria?',
    answer:
      'Existem inúmeras razões para se contratar uma consultoria empresarial, das quais, podemos citar o crescimento repentino da empresa, a sucessão de pais para filhos, a fusão ou cisão entre empresas, o aporte de investimentos, a necessidade de profissionalização da gestão, dentre outros.',
  },
]

const methodologyItems = [
  {
    count: 1,
    title: 'Diagnóstico',
    description: (
      <>
        Nesta etapa, iremos gerar um relatório histórico com os principais
        indicadores que direcionam o resultado da empresa. Entendemos a relação
        entre os KPIs, construindo uma árvore de indicadores, desde o nível
        estratégico e tático ao operacional.
      </>
    ),
  },
  {
    count: 2,
    title: 'Plano de metas',
    description: (
      <>
        Estabeleceremos um plano de metas SMART, com metas específicas,
        mensuráveis, atingíveis, relevantes e atemporais. O plano de metas é
        construído com meritocracias ligadas aos donos dos KPIs, para
        recompensar o cumprimento de metas.
      </>
    ),
  },
  {
    count: 3,
    title: 'Report mensal',
    description: (
      <>
        Nesta etapa ocorre o processo decisório embasado no relatório mensal,
        comparando-se o resultado previsto versus realizado dos KPIs para
        mensurar o desempenho do negócio. Os donos dos KPIs são treinados para
        analisar, compilar e apresentar resultados.
      </>
    ),
  },
  {
    count: 4,
    title: 'Implementação',
    description: (
      <>
        Através dos rituais de gestão da mudança organizacional, acompanhamos a
        implementação das ações, definidas durante as reuniões mensais de
        resultado, para melhorar os indicadores que estiverem abaixo das metas.
      </>
    ),
  },
]

const featuresItems = [
  {
    title: `Gestão por Indicadores`,
    description: (
      <>
        Criação de uma estrutura de relatórios gerenciais (indicadores) que
        representem a empresa, como o fluxo de caixa, administração de capital
        de giro, demonstração de resultado, retorno sobre investimentos,
        planejamento e controle orçamentários, dentre outros. Acompanhamento
        mensal de reuniões de conselho.
      </>
    ),
    link: `/controladoria/gestao-por-indicadores-kpis`,
  },
  {
    title: `Apuração de Custos`,
    description: (
      <>
        Consultoria que tem como premissa melhorar a lucratividade. Estruturação
        de processos confiáveis de apuração e monitoramento de custos. Custeio
        fixo ou variável, determinação de margem de contribuição, análise de
        ociosidade de mão de obra e equipamentos, centros de custos, análise de
        ponto de equilíbrio, dentre outros.
      </>
    ),
    link: `/controladoria/apuracao-de-custos`,
  },
  {
    title: `Formação de Preços`,
    description: (
      <>
        Consultoria para análise de preços adotados pela concorrência, margem de
        lucro por produto ou serviço, ponto de equilíbrio, estratégias de
        diferenciação, estruturação do processo de precificação levando em
        consideração os indicadores que compõem o preço, como: custos, tributos,
        comissões, margem, dentre outros.
      </>
    ),
    link: `/controladoria/formacao-de-precos`,
  },
  {
    title: `Estudos de viabilidade`,
    description: (
      <>
        Apresentação de estudo de viabilidade econômico financeira para entender
        se um projeto é viável ou não. O estudo contempla projeção de receitas,
        custos, despesas, investimentos, fluxo de caixa, indicadores, retorno
        sobre o investimento, taxa mínima de atratividade e payback. Simulação
        de possíveis cenários para aderência do projeto.
      </>
    ),
    link: `/controladoria/estudos-de-viabilidade`,
  },
]

const ControladoriaPage: React.FC<PageProps> = ({ location }) => {
  return (
    <Layout location={location}>
      <SEO title="High Jump - Gestão por processos de negocio BPM" />
      <div className={styles.solutions}>
        <Menu mainFolder="Controladoria" />
        <main>
          <section className={clsx(styles.intro, styles.singleTitle)}>
            <img src={mainHeader} alt="High jump Consulting" />
            <div className={clsx(styles.mainTitle)}>
              <h1>
                Maximize Resultados Aplicando Boas Práticas de Planejamento e
                Gestão Empresarial
              </h1>
              <p>
                Conheça as nossas soluções para uma gestão empresarial eficaz.
                Otimize os números na sua empresa através da criação de metas,
                planos de meritocracia, apuração de resultados e planos de ação
                consistentes.
              </p>
            </div>
          </section>
          <div className={styles.container}>
            <BreadcrumbComponent />
            <div className={styles.intention}>
              <div className={styles.title}>
                <h1>Nosso Propósito</h1>
                <span></span>
                <p>
                  Aplicar boas práticas de planejamento e gestão empresarial
                  para alavancar resultados, promovendo a descentralização e
                  embasando a tomada de decisões.
                </p>
              </div>
              <div className={styles.mainFeatures}>
                <div className={styles.listItems}>
                  {featuresItems.map((item, key) => (
                    <div className={styles.item} key={key}>
                      <h4>{item.title}</h4>
                      <p>{item.description}</p>
                      {item.link && (
                        <AnchorLink to={item.link}>Saiba mais</AnchorLink>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <MethodologySection
              items={methodologyItems}
              descriptionSection="Conheça os 04 principais passos de nossa metodologia para implementar um modelo de gestão eficaz."
            />
          </div>

          <div className={styles.cases}>
            <div className={styles.title}>
              <h1>Quem conhece, confia!</h1>
            </div>
            <SliderCustomers customers={controladoriaList} />
          </div>

          <div className={styles.statistics}>
            <StatisticsSection />
          </div>

          <div className={styles.container}>
            <div className={styles.features}>
              <div className={styles.title}>
                <h1>Diferenciais</h1>
              </div>
              <div className={styles.items}>
                <div className={styles.div1}>
                  <h2>Equipe multidisciplinar</h2>
                  <p>
                    Especialistas por área, trazendo as melhores práticas de
                    mercado
                  </p>
                </div>
                <div className={styles.div2}>
                  <h2>Boas práticas FIPECAFI</h2>
                  <p>Aplicação de boas práticas contábeis e fiscais</p>
                </div>
                <div className={styles.div3}>
                  <h2>Confiança no resultado</h2>
                  <p>Almejando o retorno sobre o investimento</p>
                </div>
                <div className={styles.div4}>
                  <h2>Modalidade híbrida</h2>
                  <p>
                    Execução presencial e/ou remota em comum acordo com o
                    cliente
                  </p>
                </div>
              </div>
            </div>
          </div>

          <Contact />
          <FaqSection items={faqItems} />
          <Footer />
        </main>
      </div>
    </Layout>
  )
}

export default ControladoriaPage
